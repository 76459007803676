import React from 'react';
import { Button, Card, Col, Divider, Flex, Form, Image, Row, Space } from 'antd';
import { MyInput } from '../../../components';

const Contactus = () => {
    const [form] = Form.useForm();


    return (
        <Form
            form={form}
            layout="vertical"
        >
            <Row gutter={16}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        label='Customer Service'
                        name="customerservice"
                        size={'large'}
                        type='number'
                        placeholder={'(704) 555-0127 '}
                        required
                        message='Please enter Customer Service number'
                        value={form.getFieldValue("customerservice") || ''}
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        label='Whatsapp'
                        name="whatsapp"
                        size={'large'}
                        type='number'
                        placeholder={'(704) 555-0127 '}
                        required
                        message='Please enter whatsapp number'
                        value={form.getFieldValue("whatsapp") || ''}
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        label='Website'
                        name="website"
                        size={'large'}
                        placeholder={'https://WalleyTech.org'}
                        required
                        message='Please enter website url'
                        value={form.getFieldValue("website") || ''}
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        label='Facebook'
                        name="facebook"
                        size={'large'}
                        placeholder={'www.facebook.com/WalleyTech'}
                        required
                        message='Please enter facebook url'
                        value={form.getFieldValue("facebook") || ''}
                    />
                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={24}>
                    <Space className="w-100 right">
                        <Button
                            className="btncancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            block
                            className='btnsave'
                            type="primary"
                            htmlType="submit"
                            onClick={() => form.submit()}
                        >
                            Update
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export { Contactus };
