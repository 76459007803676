import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography } from "antd"
import { MyInput, MySelect } from "../../Forms"
import { clientcityName, role, gender } from "../../../shared";

const { Title } = Typography;

const AddUser = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={edititem?'Edit user':'Add new user'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave bg-brand'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0">USER DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'User Name'
                        name="username" 
                        size={'large'}
                        placeholder={'e.g William'}
                        required
                        message='Please enter user name'
                        value={form.getFieldValue("username") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Phone Number'
                        name="phoneNo" 
                        size={'large'}
                        type='number'
                        placeholder={'+234 | '}
                        required
                        message='Please enter phone number'
                        value={form.getFieldValue("phoneNo") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Role'
                        name="role" 
                        size={'large'}
                        required
                        value={form.getFieldValue("role") || ''}
                        options={role}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Gender'
                        name="city" 
                        size={'large'}
                        required
                        value={form.getFieldValue("city") || ''}
                        options={gender}
                    />
                </Col>
                
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                <MyInput
                        label= 'Email'
                        name="email" 
                        size={'large'}
                        type='email'
                        placeholder={'e.g abc@gmail.com'}
                        required
                        message='Please enter email address'
                        value={form.getFieldValue("email") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Password'
                        name="password" 
                        size={'large'}
                        type={'password'}
                        required
                        message='Please enter password'
                        value={form.getFieldValue("password") || ''}
                    />
                </Col>
                <Col lg={{span: 24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Address'
                        name="address" 
                        size={'large'}
                        placeholder={'e.g 4140 Parker Rd. Allentown, New Mexico 31134'}
                        required
                        message='Please enter address'
                        value={form.getFieldValue("address") || ''}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddUser}