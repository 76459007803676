import React from 'react'
import { Card, Col, Row, Tabs } from 'antd'
import { ChangePassword, ModuleTopHeading, ProfileSetting , Contactus} from '../../components';

const Settings = () => {

    const accounttab = [
        {
            key: '1',
            label: 'Profile Setting',
            children: <ProfileSetting />,
        },
        {
            key: '2',
            label: 'Password Setting',
            children: <ChangePassword />
        },
        {
            key:'3',
            label:'Contact Us Setting',
            children:<Contactus/>
        }
    ];

    return (
        <React.Fragment>
            <Card className='shadow-d radius-12'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading level={4} name='Settings' />
                    </Col>
                    <Col span={24}>
                        <Tabs defaultActiveKey="1" items={accounttab}  />   
                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    )
}

export { Settings }
