import React, { useState } from 'react'
import { Modal, Space, Button, Row, Col, Typography, Table, Flex, Image, message, Divider } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const {Text, Title } = Typography
const InvoicebillingView = ({ visible, onClose, view, setBillingview }) => {

    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: 'Columnone',
            dataIndex: 'columnone',
            width: 150,
        },
        {
            title: 'Columntwo',
            dataIndex: 'columntwo',
            width: 150,
        },
        {
            title: 'Columnthree',
            dataIndex: 'columnthree',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            columnone: <Space direction='vertical' size={2}>
                <Text className='fw-500'>Payment From</Text>
                <Flex gap={5}>
                    <Text>William John</Text>
                </Flex>
            </Space>,
            columntwo: <Space direction='vertical' size={2}>
                <Text className='fw-500'>Organization Name</Text>
                <Text>MZ Enterprise</Text>
            </Space>,
            columnthree: <Space direction='vertical' size={2}>
                <Text className='fw-500'>Purchase Email</Text>
                <Text>william@gmail.com</Text>
            </Space>,
        },
        {
            key: '2',
            columnone: <Space direction='vertical' size={2}>
                <Text className='fw-500'>License Number</Text>
                <Flex gap={5}>
                    <Text>SUB-564321</Text>
                </Flex>
            </Space>,
            columntwo: <Space direction='vertical' size={2}>
                <Text className='fw-500'>Billing Frequency</Text>
                <Text>Monthly</Text>
            </Space>,
            columnthree: <Space direction='vertical' size={2}>
                <Text className='fw-500'>Payment Method</Text>
                <Text>PK2A************122</Text>
            </Space>,
        },
    ];
    const tabletwoColumns = [
        {
            title: 'Desc',
            dataIndex: 'desc',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            width:100,
        },
    ];

    const tabletwoData = [
        {
            key:'1',
            desc:<Text className='fw-500'>Description</Text>,
            price:<Text className='fw-500'>Price</Text>
        },
        {
            key:'2',
            desc:<Text>1 month license purchase - Device type MV101From December 01, 2024 to January 01, 2025</Text>,
            price:<Text>$1200</Text>
        }
    ];
    return (
        <div>
            <Modal
                width={600}
                title={null}
                className='shadow-c modal-cs footer-none'
                open={visible}
                onOk={onClose}
                onCancel={onClose}
                closeIcon={false}
                centered
                footer={
                    <Flex>
                       <Text className='fs-12 text-start'>WallyTech Nigeria Limited Company, Ground Floor Shehu Shagari Way,
                         Maitama 
                       VAT: IE9740425P</Text>
                       <Text className='fs-12 text-nowrap'>
                        Page 1 of 1
                       </Text>
                    </Flex>
                }
            >
                <Flex align='center' justify='end' className='header-modal border0'>

                    <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                        <CloseOutlined className='text-gray' />
                    </Button>
                </Flex>
                <div
                    className='content-modal pb-2'
                >
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Row gutter={[24, 24]} justify={'space-between'}>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                    <Image src='assets/images/logo.png' width={100} height={90} />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                                    <Flex vertical gap={5}>
                                        <Title level={5}>INV-102344</Title>
                                        <Text>Transaction Date: 10/02/2024</Text>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Table
                                size='large'
                                columns={columns}
                                dataSource={data}
                                scroll={{ x: 500 }}
                                className='pagination'
                                showSorterTooltip={false}
                                rowHoverable={false}
                                showHeader={false}
                                pagination={false}
                            />
                        </Col>
                        <Col span={24}>
                            <Table
                                size='large'
                                columns={tabletwoColumns}
                                dataSource={tabletwoData}
                                scroll={{ x: 500 }}
                                className='pagination custom-table'
                                showSorterTooltip={false}
                                rowHoverable={false}
                                showHeader={false}
                                pagination={false}
                            />
                        </Col>
                        <Col span={24}>
                        <Row gutter={[16,5]}>
                            <Col xs={{span:20}} sm={{span:20}} md={{span:20}} lg={{span:20}} xl={{span:20}}>
                            <Flex justify='end'>
                            <Text>Subtotal </Text>
                            </Flex>
                            </Col>
                            <Col xs={{span:4}} sm={{span:4}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
                            <Text>$1200</Text>
                            </Col>
                            <Col xs={{span:20}} sm={{span:20}} md={{span:20}} lg={{span:20}} xl={{span:20}}>
                                <Flex justify='end'>
                                <Text>Tax:(0%) </Text>
                                </Flex>
                            </Col>
                            <Col xs={{span:4}} sm={{span:4}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
                            <Text>$0</Text>
                            </Col>
                            <Col span={24}>
                            <Divider className='my-0'/>
                            </Col>
                            <Col xs={{span:20}} sm={{span:20}} md={{span:20}} lg={{span:20}} xl={{span:20}}>
                                <Flex justify='end'>
                                <Text>Total </Text>
                                </Flex>
                            </Col>
                            <Col xs={{span:4}} sm={{span:4}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
                            <Text>$1200</Text>
                            </Col>
                        </Row>
                        
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export { InvoicebillingView }