import React, { useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Form, Image, Row, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import { AddUser, Usertable, ModuleTopHeading, SearchInput } from '../../components';

const { Text } = Typography
const Users = () => {

    const [ visible, setVisible] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [form] = Form.useForm();
    const items = [
        { label: <a href="#" className='fs-13'>A - Z</a>, key: 0 },
        { label: <a href="#" className='fs-13'>Z - A</a>, key: 1 },
      ];
  
   
    return (
        <React.Fragment>
            <Card className='shadow-d radius-12'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading level={5} name='Users' onClick={()=>  setVisible(true)} />
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            className='pt-3'
                        >
                            <Row gutter={[16,16]} align={'middle'}>
                                <Col lg={8} md={12} sm={24} xs={24}>
                                    <SearchInput 
                                        name='name'
                                        placeholder='Search by account number'
                                        value={form.getFieldValue('name') || ''}
                                        prefix={<img src='/assets/icons/search.png' width={20} />}
                                        className='border-light-gray pad-x ps-0 radius-8 fs-13 search-w'
                                    />
                                </Col>
                                <Col lg={16} md={12} sm={24} xs={24}>
                                    <Flex justify='end'>
                                        <Dropdown
                                            menu={{items}}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter text-black mbl-btn'>
                                                Filter 
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Flex>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Usertable 
                            {...{setEditItem,setVisible}}
                        />
                    </Col>
                </Row>
            </Card>
            <AddUser
                visible={visible} 
                edititem={edititem}
                onClose={()=>{setVisible(false);setEditItem(null)}}
            />
        </React.Fragment>
    )
}

export { Users }
