import React from 'react'
import { Breadcrumb, Button, Card, Col, Row, Space, Table, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { clientData } from '../../../Data';
import { Clientdevicetable } from '../Clientdevicetable';

const { Text } = Typography
const ClientSingleview = () => {
    let { clientId } = useParams()
    const navigate = useNavigate()
    const clientDetails = clientData.find((mod)=>mod.id === clientId)    

    const columns = [
        {
            title: 'Client',
            dataIndex: 'client',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            client: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Client Name</Text>
                <Text>{clientDetails?.clientname}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Phone Number</Text>
                <Text>{clientDetails?.phonenumber}</Text>
            </Space>,
        },
        {
            key: '1',
            client: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Organization Name</Text>
                <Text>{clientDetails?.organizationname}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Email Address</Text>
                <Text>{clientDetails?.email}</Text>
            </Space>,
        },
        {
            key: '2',
            client: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Country</Text>
                <Text>{clientDetails?.country}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>City</Text>
                <Text>
                    Lagos
                </Text>
            </Space>,
        },
    ];

    
  

    return (
        <div>
            <Breadcrumb className='mb-3'
                separator=">"
                items={[
                    {
                      title: <Text className='cursor text-gray' onClick={()=>navigate('/clients')}>Clients</Text>,
                    },
                    {
                      title: <Text className='fw-500'>{clientDetails?.clientname}</Text>,
                    },
                ]}
            />
            <Card className='shadow-d radius-12 mb-3'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Space>
                            <Button className='border0 p-0 bg-transparent' onClick={()=>navigate('/clients')}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Text className='fw-500'>{clientDetails.clientname}</Text>
                            <Text className='active fs-12 badge-cs'>
                                {clientDetails?.accountnumber}
                            </Text>
                        </Space>
                    </Col>  
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='pagination'
                            showSorterTooltip={false}
                            rowHoverable={false}
                            showHeader={false}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </Card>
            <Clientdevicetable />
        </div>
    )
}

export { ClientSingleview }
