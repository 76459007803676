export const deviceData = [
    {
        id:1,
        devicetype:'NV910D',
        devicename:'Device 1',
        devicenumber:'INV-991JK',
        clientname:'Susan Sumanggih',
        vehiclenumber:'ABC-999DE',
        status:'Active',
        regdate:'2021-01-01',
    },
    {
        id:2,
        devicetype:'MV920G',
        devicename:'Device 2',
        devicenumber:'INV-992JK',
        clientname:'Lukman Farhan',
        vehiclenumber:'ABC-999DE',
        status:'Inactive',
        regdate:'2025-12-12',
    },
    {
        id:3,
        devicetype:'HV930K',
        devicename:'Device 3',
        devicenumber:'INV-993JK',
        clientname:'Hari Danang',
        vehiclenumber:'ABC-999DE',
        status:'Active',
        regdate:'2020-08-08',
    },
]