import React, { useState } from 'react'
import { Modal, Space, Typography, Image } from 'antd'
import { apiCalls } from '../../../shared/Apis';
const { Title, Text } = Typography
const SuccessModal = ({successmodal,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)

    // const DeleteProductApi = async() => {
    //     setLoading(true)
    //     try{
    //         const result = await apiCalls.vendorApi.DeleteProductApi(deletevendor?.id)
    //         setLoading(false)
    //         onClose();
    //         getVendorApplications();
    //     } catch (error){
    //         console.error("Error deleting data",error)
    //     }
    // }
    
  return (
    <div>
        <Modal width={500} className='shadow-c'  open={successmodal} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <Image src='/assets/icons/confirm.png' width={50} preview={false} className='mb-2'/>
                <Text className='text-center fw-500'>
                    Status Changed Successfully
                </Text>
            </Space>
        </Modal>
    </div>
  )
}

export {SuccessModal}