import React, { useState } from 'react'
import { Breadcrumb, Button, Card, Col, Flex, Row, Space, Table, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { licenseDatas } from '../../../Data';
import { LicenseBillingTable } from '../LicenseBillingTable';
import { AddLicense } from '../LicenseDrawers';

const { Text } = Typography
const SingleViewLicense = () => {
    let { licenseId } = useParams()
    const navigate = useNavigate()
    const licenseDetail = licenseDatas.find((mod)=>mod.key === licenseId) 
    const [ renewstate, setRenewState ] = useState(false)

    const columns = [
        {
            title: 'License',
            dataIndex: 'license',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            license: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Client Name</Text>
                <Text>{licenseDetail?.clientname}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Account Number</Text>
                <Text>{licenseDetail?.accountno}</Text>
            </Space>,
        },
        {
            key: '2',
            license: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Device Type</Text>
                <Text>-</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Device Number</Text>
                <Text>
                    {licenseDetail?.devicenumber}
                </Text>
            </Space>,
        },
    ];

    
  

    return (
        <React.Fragment>
            <Breadcrumb className='mb-3'
                separator=">"
                items={[
                    {
                      title:<Text className='cursor text-gray' onClick={()=>navigate('/license')}>License</Text>,

                    },
                    {
                      title:<Text className='fw-500'>{licenseDetail?.licenseno}</Text>,
                    },
                ]}
            />
            <Card className='shadow-d radius-12 border0 mb-3'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex wrap justify='space-between'>
                            <Space>
                                <Button className='border0 p-0 bg-transparent' onClick={()=>navigate('/license')}>
                                    <ArrowLeftOutlined />
                                </Button>
                                <Text className='fw-600'>{licenseDetail.licenseno}</Text>
                                <Text
                                    className={
                                        licenseDetail.status === "Active" ? "active fs-12 badge-cs" :
                                        licenseDetail.status === "Inactive" ? "inactive fs-12 badge-cs" : null
                                    } 
                                >
                                    {licenseDetail?.status}
                                </Text>
                            </Space>
                            <Flex gap={10}>
                                {
                                    licenseDetail?.status === 'Inactive' &&
                                    <Button className='btncancel pad-filter text-black mbl-btn'
                                        icon={<img src='/assets/icons/renew.png' width={24} />}
                                        onClick={()=>{setRenewState(true)}}
                                    >
                                        Renew License 
                                    </Button>
                                }
                                <Button className='btncancel pad-filter text-black mbl-btn'
                                    icon={<img src='/assets/icons/print.png' width={24} />}
                                >
                                    Print 
                                </Button>
                            </Flex>
                        </Flex>
                    </Col>  
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            className='pagination'
                            showSorterTooltip={false}
                            rowHoverable={false}
                            showHeader={false}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </Card>
            <LicenseBillingTable status={licenseDetail?.status} />
            <AddLicense 
                visible={renewstate}
                renewstate={renewstate}
                onClose={()=>setRenewState(false)}
            />
        </React.Fragment>
    )
}

export { SingleViewLicense }
