export const roleData = [
    {
        id:'1',
        role:'Sub-Admin',
        status:'Active'
    },
    {
        id:'2',
        role:'Typist',
        status:'Inactive'
    },
    {
        id:'3',
        role:'Technical Officer',
        status:'Active'
    },
    {
        id:'4',
        role:'App Engineer',
        status:'Inactive'
    },
]