export const clientdashData = [
    {
        key:'1',
        clientname:'Susan Sumanggih',
        phonenumber:'(209) 555-0104',
        city:'Lagos',
        devicereg:'1',
        status:'Active',
        regdate:'2021-01-01',
    },
    {
        key:'2',
        clientname:'Lukman Farhan',
        phonenumber:'(671) 555-0110',
        city:'Kano',
        devicereg:'2',
        status:'Inactive',
        regdate:'2025-12-12',
    },
    {
        key:'3',
        clientname:'Hari Danang',
        phonenumber:'(229) 555-0109',
        city:'Lagos',
        devicereg:'4',
        status:'Active',
        regdate:'2020-08-08',
    },
]