import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col, Button, Space, Typography } from "antd"
import { MyDatepicker, MyInput, MySelect, SingleFileUpload, StoreSingleFileUpload } from "../../Forms"
import { deviceData } from "../../../Data";
import { clientData } from "../../../Data";

const { Title } = Typography;

const AddLicense = ({ visible, onClose, renewstate }) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (visible) {

        } else {
            form.resetFields();
        }
    }, [visible])

    const closeDrawer = () => {

        form.resetFields()
        onClose()
    }

    // device numbers select dropdown 
    const deviceNumber = deviceData?.map((item) => ({
        id: item?.id,
        name: item?.devicenumber,
    }));

    // change device Type 
    const handleDeviceNumberChange = (value) => {
        const selectedDevice = deviceData.find((item) => item.devicenumber === value);
        form.setFieldsValue({
            devicetype: selectedDevice?.devicetype || ''
        });
    };

    // Account Number select dropdown 

    const accountNumber  = clientData?.map((item) => ({
        id: item?.id,
        name: item?.accountnumber,
    }))

    // change device account number 
    const handleAccountNumberChange = (value) =>{
        const selectedAccountNumber = clientData.find((item) => item.accountnumber === value);
        form.setFieldsValue({
            clientname: selectedAccountNumber?.clientname || '',
        })
    }
    return (
        <Drawer
            title={renewstate ? 'Renew license' : 'Add new license'}
            onClose={closeDrawer}
            className="drawer"
            open={visible}
            width={600}
            footer={
                <Space className="w-100 right">
                    <Button
                        onClick={closeDrawer}
                        className="btncancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        block
                        className='btnsave bg-brand'
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        onClick={() => form.submit()}
                    >
                        Save
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
            // onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Title level={5} className="mb-3 mt-0">LICENSE  DETAILS</Title>
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MySelect
                            label='Account Number'
                            name="accountNo"
                            size={'large'}
                            required
                            message='Please choose account number'
                            value={form.getFieldValue("accountNo") || ''}
                            options={accountNumber}
                            onChange={handleAccountNumberChange}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MyInput
                            label='Client Name'
                            name="clientname"
                            size={'large'}
                            value={form.getFieldValue("clientname") || ''}
                            disabled={renewstate}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MySelect
                            label='Device Number'
                            name="devicenumber"
                            size={'large'}
                            required
                            message='Please choose device number'
                            value={form.getFieldValue("devicenumber") || ''}
                            options={deviceNumber}
                            onChange={handleDeviceNumberChange}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MyInput
                            label='Device Type'
                            name="devicetype"
                            size={'large'}
                            required
                            message='Please enter device type'
                            value={form.getFieldValue("devicetype") || ''}
                            disabled={renewstate}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MySelect
                            label='Plan'
                            name="plan"
                            size={'large'}
                            required
                            message='Please choose plan name'
                            value={form.getFieldValue("clientname") || ''}
                            options={[
                                {
                                    id: 1,
                                    name: 'Monthly'
                                },
                                {
                                    id: 2,
                                    name: 'Annually'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MyDatepicker
                            datePicker
                            label='Issue Date'
                            name="issuedate"
                            size={'large'}
                            required
                            message='Please enter issue date'
                            value={form.getFieldValue("issuedate") || ''}
                            className='border-gray'
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MySelect
                            label='Payment Method'
                            name="paymentmethod"
                            size={'large'}
                            required
                            message='Please choose payment method'
                            value={form.getFieldValue("paymentmethod") || ''}
                            options={[
                                {
                                    id: 1,
                                    name: 'Cash'
                                },
                                {
                                    id: 2,
                                    name: 'Bank Transfer'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MySelect
                            label='Payment Status'
                            name="paymentstatus"
                            size={'large'}
                            required
                            message='Please choose payment status'
                            value={form.getFieldValue("paymentstatus") || ''}
                            options={[
                                {
                                    id: 1,
                                    name: 'Paid'
                                },
                                {
                                    id: 2,
                                    name: 'Unpaid'
                                },
                            ]}
                        />
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                        <SingleFileUpload label={'Receipt'} title={'Upload Receipt'} form={form} />
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export { AddLicense }