import React, { useState } from 'react'
import { Modal, Space, Button, Row, Col, Typography, Table, Flex, Image, message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const { Text, Title } = Typography
const InvoiceView = ({ visible, onClose, view, setBillingview }) => {

    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: 'Invoice',
            dataIndex: 'invoice',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            invoice: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Device Name</Text>
                <Flex gap={5}>
                    <Text>{view?.devicename}</Text>
                </Flex>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Issue Date</Text>
                <Text>{view?.issuedate}</Text>
            </Space>,
        },
        {
            key: '2',
            invoice: <Space direction='vertical' size={2}>
            <Text className='text-gray'>License Number</Text>
            <Text>{view?.licenseno}</Text>
        </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Due Date</Text>
                <Text>
                    {view?.duedate}
                </Text>
            </Space>,
        },
        {
            key: '3',
            invoice: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Plan</Text>
            <Text>{view?.plan}</Text>
        </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Billing Type</Text>
                <Text>New Subscription</Text>
            </Space>
        },
        {
            key: '4',
            invoice: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Payment Method</Text>
            <Text>{view?.paymentmethod}</Text>
        </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Amount</Text>
                <Text>{view?.amount}</Text>
            </Space>
        },
    ];
    return (
        <div>
            <Modal
                width={600}
                title={null}
                className='shadow-c modal-cs'
                open={visible}
                onOk={onClose}
                onCancel={onClose}
                closeIcon={false}
                centered
                footer={
                    <Space style={{ justifyContent: "end" }} className="w-100">
                        <Button
                            onClick={onClose}
                            className='btncancel'
                        >
                            Cancel
                        </Button>
                        <Button
                            block
                            type="primary"
                            className='btnsave text-white'
                            loading={loading}
                            onClick={() =>{onClose(); setBillingview(true)}}

                        >
                            Print
                        </Button>
                    </Space>
                }
            >
                <Flex align='center' justify='space-between' className='header-modal'>
                    <Space>
                    <Text strong>{view?.Invoicenumber}</Text>
                    <Text className={view?.status === 'Paid' ? 'badge-cs active' : 'badge-cs inactive'}>{view?.status}</Text>
                    </Space>
                    <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                        <CloseOutlined className='text-gray' />
                    </Button>
                </Flex>
                <div
                    className='content-modal pb-2'
                >
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Table
                                size='large'
                                columns={columns}
                                dataSource={data}
                                scroll={{ x: 500 }}
                                className='pagination'
                                showSorterTooltip={false}
                                rowHoverable={false}
                                showHeader={false}
                                pagination={false}
                            />
                        </Col>
                        {
                            view?.status === 'Paid' ?
                                <Col span={24}>
                                    <Flex vertical gap={10}>
                                        <Title level={5} className='m-0'>Receipt</Title>
                                        <Image src='/assets/images/av-1.png' width={120} className='radius-8' />
                                    </Flex>
                                </Col>
                                : null
                        }
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export { InvoiceView }