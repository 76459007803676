export const clientData = [
    {
        id:'1',
        clientname:'Susan Sumanggih ',
        accountnumber:'123',
        organizationname:'Elvilloe',
        phonenumber:'(209) 555-0104',
        email:'example@gmail.com',
        city:'Lagos',
        country:'India',
        deviceregistered:'1',
        status:'Active',
        registereddata:'10/20/2024',     
    },
    {
        id:'2',
        clientname:'Lukman Farhan ',
        accountnumber:'456',
        organizationname:'Itionom Inc.',
        phonenumber:'(209) 555-0104',
        email:'example@gmail.com',
        city:'Kino',
        country:'India',
        deviceregistered:'3',
        status:'Inactive',
        registereddata:'10/20/2024',     
    },
    {
        id:'3',
        clientname:'Hari Danang ',
        accountnumber:'789',
        organizationname:'BioZino Co.',
        phonenumber:'(209) 555-0104',
        email:'example@gmail.com',
        city:'Kino',
        country:'India',
        deviceregistered:'1',
        status:'Active',
        registereddata:'10/20/2024',     
    },
    {
        id:'4',
        clientname:'Farrel Kurniawan',
        accountnumber:'101',
        organizationname:'Curcee Ltd.',
        phonenumber:'(209) 555-0104',
        email:'example@gmail.com',
        city:'Lagos',
        country:'India',
        deviceregistered:'1',
        status:'Active',
        registereddata:'10/20/2024',     
    },
    {
        id:'5',
        clientname:'Yanto Jericho',
        accountnumber:'102',
        organizationname:'Greenbers  Inc.',
        phonenumber:'(209) 555-0104',
        email:'example@gmail.com',
        city:'Kino',
        country:'India',
        deviceregistered:'1',
        status:'Active',
        registereddata:'10/20/2024',     
    },
    {
        id:'6',
        clientname:'Rina Karina',
        accountnumber:'103',
        organizationname:'Alizates Co.',
        phonenumber:'(209) 555-0104',
        email:'example@gmail.com',
        city:'Aba',
        country:'India',
        deviceregistered:'1',
        status:'Active',
        registereddata:'10/20/2024',     
    },
    {
        id:'7',
        clientname:'Dimas Kamal',
        accountnumber:'104',
        organizationname:'Habidence Inc.',
        phonenumber:'(209) 555-0104',
        email:'example@gmail.com',
        city:'IIesa',
        country:'India',
        deviceregistered:'1',
        status:'Active',
        registereddata:'10/20/2024',     
    },
]