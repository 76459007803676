import React, { useState } from 'react'
import { Button, Switch, Table, Typography, Space, Image } from 'antd'
import { clientData } from '../../../Data';
import { ActionButton, AlertModal } from '../../../components';
import { useNavigate } from 'react-router-dom';
const { Text } = Typography;
const Clienttable = ({ setVisible, setEditItem }) => {

    const navigate = useNavigate()
    const [deleteitem,setDeleteItem] = useState(false)
    const [switchtext, setSwitchText] = useState(false)
    const onChange = () => {
        setSwitchText(true)
        setDeleteItem(true)
    };


    const columns = [
        {
            title: <Text className='fs-15 fw-500 text-nowrap'>Account Number</Text>,
            dataIndex: 'accountnumber'
        },
        {
            title: <Text className='fs-15 fw-500 text-nowrap'>Client Name</Text>,
            dataIndex: 'clientname',
            sorter: (a,b) => a.clientname.localeCompare(b.clientname),
        },
        {
            title: <Text className='fs-15 fw-500 text-nowrap'>Organization Name</Text>,
            dataIndex: 'organizationname',
        },
        {
            title: <Text className='fs-15 fw-500 text-nowrap'>Phone Number</Text>,
            dataIndex: 'phonenumber'
        },
        {
            title: <Text className='fs-15 fw-500 text-nowrap'>City</Text>,
            dataIndex: 'city'
        },
        {
            title: <Text className='fs-15 fw-500 text-nowrap'>Devices Regiestered</Text>,
            dataIndex: 'deviceregistered'

        },
        {
            title: <Text className='fs-15 fw-500 text-nowrap'>Status</Text>,
            dataIndex: 'status',
            render: (status) => {
                if (status === 'Active') {
                    return <Text className='active fs-12 badge-cs'>Active</Text>;
                } else {
                    return <Text className='inactive fs-12 badge-cs'>Inactive</Text>;
                }
            },
        },
        {
            title: <Text className='fs-15 fw-500 text-nowrap'>Registeration Date</Text>,
            dataIndex: 'registereddata'
        },
        {
            title: <Text className='fs-15 fw-500 text-nowrap'>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <Switch size='small' defaultChecked onChange={onChange} />
                    <ActionButton
                        title='View client'
                        shape="circle"
                        icon={<Image src='/assets/icons/view.png' width={18} preview={false} />}
                        onClick={() => navigate(`/clients/clientview/${row?.id}`)}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Edit client'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({ name: row?.clientname }) }}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete Client'
                        shape="circle"
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        className='border0 bg-transparent'
                        onClick={() => {setDeleteItem({name: row?.clientname})}}
                    />
                </Space>
            ),
        },
    ];
    return (
        <>
             <Table
            size='large'
            columns={columns}
            dataSource={clientData}
            className='pagination'
            showSorterTooltip={false}
            scroll={{ x: 1600 }}
            pagination={{
                hideOnSinglePage: true,
                total: 12,
                // pageSize: pagination?.pageSize,
                // defaultPageSize: pagination?.pageSize,
                // current: pagination?.pageNo,
                // size: "default",
                // pageSizeOptions: ['10', '20', '50', '100'],
                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
            }}
        />
        <AlertModal
          visible={deleteitem}
          deleteitem={deleteitem}
          switchtext={switchtext}
          onClose={()=>{setDeleteItem(false);setSwitchText(null)}}
        />
        </>
    )
}

export { Clienttable }