import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col, Button, Space, Typography } from "antd"
import { MyInput, MySelect } from "../../Forms"
import { clientName, categories, priority } from "../../../shared";

const { Title } = Typography;

const Addhelpdesk = ({ visible, onClose, edititem }) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (visible && edititem) {

        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer = () => {

        form.resetFields()
        onClose()
    }

    return (
        <Drawer
            title={edititem ? 'Edit Ticket' : 'Add New Ticket'}
            onClose={closeDrawer}
            className="drawer"
            open={visible}
            width={600}
            footer={
                <Space className="w-100 right">
                    <Button
                        onClick={closeDrawer}
                        className="btncancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        block
                        className='btnsave bg-brand'
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        onClick={() => form.submit()}
                    >
                        {edititem ? 'Update' : 'Save'}
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
            // onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Title level={5} className="mb-3 mt-0">TICKET DETAILS</Title>
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MySelect
                            label='Client Name'
                            name="clientname"
                            size={'large'}
                            required
                            value={form.getFieldValue("clientname") || ''}
                            options={clientName}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MyInput
                            label='Phone Number'
                            name="phoneNo"
                            size={'large'}
                            type='number'
                            placeholder={'+234 | '}
                            required
                            message='Please enter phone number'
                            value={form.getFieldValue("phoneNo") || ''}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MySelect
                            label='Categorry'
                            name="category"
                            size={'large'}
                            required
                            value={form.getFieldValue("category") || ''}
                            options={categories}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MySelect
                            label='Priority'
                            name="priority"
                            size={'large'}
                            required
                            value={form.getFieldValue("priority") || ''}
                            options={priority}
                        />
                    </Col>
                    <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MyInput
                            label='Subject'
                            name="subject"
                            size={'large'}
                            placeholder={'e.g William'}
                            required
                            message='Write ticket subject'
                            value={form.getFieldValue("subject") || ''}
                        />
                    </Col>
                    <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MyInput
                            label='Description'
                            name="description"
                            textArea
                            size={'large'}
                            placeholder={'e.g William'}
                            required
                            message='Write ticket description'
                            value={form.getFieldValue("description") || ''}
                        />
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <MySelect
                            label='Assigned To'
                            name="assignedto"
                            size={'large'}
                            required
                            value={form.getFieldValue("assignedto") || ''}
                            options={priority}
                        />
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export { Addhelpdesk }