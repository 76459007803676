export const userData = [
    {
        id:'1',
        username:'Shujat ali ',
        role:'owner',
        gender:'Male',  
        phonenumber:'(209) 555-0104',
        email:'shuajt@gmail.com',
        address:'Swat (KPK)'
    },
    {
        id:'2',
        username:'Azeem ali ',
        role:'admin',
        gender:'Male',  
        phonenumber:'(209) 555-0104',
        email:'azeem@gmail.com',
        address:'Islamabad (KPK)'
    },
    {
        id:'3',
        username:'Abdullah ali ',
        role:'admin',
        gender:'Male',  
        phonenumber:'(209) 555-0104',
        email:'abdullah@gmail.com',
        address:'Rawalpindi (KPK)'
    },
    {
        id:'4',
        username:'hanan ali ',
        role:'owner',
        gender:'Male',  
        phonenumber:'(209) 555-0104',
        email:'abdullah@gmail.com',
        address:'Rawalpindi (KPK)'
    },
]