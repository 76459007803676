const deviceType = [
    {
        id: 1,
        name:'MV810G'
    },
    {
        id: 2,
        name:'MV710G'
    },
    {
        id: 3,
        name:'MV720G'
    },
]


const clientName = [
    {
        id: 1,
        name:'Susan Sumanggih'
    },
    {
        id: 2,
        name:'Lukman Farhan'
    },
    {
        id: 3,
        name:'Rina Karina'
    },
]

const clientcityName = [
    {
        id: 1,
        name:'Swat'
    },
    {
        id: 2,
        name: 'Mardan'
    }
]

const countryName = [
    {
        id: 1,
        name:'Saudi Arab'
    },
    {
        id: 2,
        name: 'Pakistan'
    }
]

const role = [
    {
        id:'1',
        name:'Owner'
    },
    {
        id:'2',
        name:'Admin'
    }
]
const gender = [
    {
        id:'1',
        name:'Male'
    },
    {
        id:'2',
        name:'Female'
    }
]

const categories = [
    {
        id:'1',
        name:'Technical'
    },
    {
        id:'2',
        name:'Non Technical'
    }
]

const priority = [
    {
        id:'1',
        name:'High'
    },
    {
        id:'2',
        name:'Low'
    }
]
export {clientName,clientcityName,countryName, deviceType,role, gender,categories,priority}