import React from 'react'
import { Breadcrumb, Button, Card, Col, Row, Space, Table, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { helpDesk } from '../../../Data';
import { HelpComponent } from '../HelpComment';

const { Text } = Typography
const HelpSupportSingleView = () => {
    let { helpId } = useParams()
    const navigate = useNavigate()
    const helpData = helpDesk.find((mod)=>mod.key === helpId)    

    const columns = [
        {
            title: 'Ticket',
            dataIndex: 'ticket',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            ticket: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Client Name</Text>
                <Text>{helpData?.clientname}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Contact Number</Text>
                <Text>{helpData?.phonenumber}</Text>
            </Space>,
        },
        {
            key: '2',
            ticket: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Category</Text>
                <Text>{helpData?.category}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Priority</Text>
                <Text
                    className={
                        helpData.priority === "High" ? "inactive fs-12 badge-cs" :
                        helpData.priority === "Urgent" ? "darkred fs-12 badge-cs" :
                        helpData.priority === "Low" ? "orange fs-12 badge-cs" : 
                        helpData.priority === "Medium" ? "blue fs-12 badge-cs" : null
                    } 
                >
                    {helpData?.priority}
                </Text>
            </Space>,
        },
        {
            key: '3',
            ticket: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Assigned To</Text>
                <Text>{helpData?.clientname}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Date</Text>
                <Text>{helpData?.registerationdate}</Text>
            </Space>,
        },
        {
            key: '4',
            ticket: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Subject</Text>
                <Text>Device not working properly</Text>
            </Space>,
        },
        {
            key: '5',
            ticket: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Ticket Description</Text>
                <Text>Lorem Ipsum</Text>
            </Space>
        },
    ];

    
  

    return (
        <div>
            <Breadcrumb className='mb-3'
                separator=">"
                items={[
                    {
                      title:<Text className='cursor text-gray' onClick={()=>navigate('/helpdesk')}>Help Desk</Text>,

                    },
                    {
                      title:<Text className='fw-500'>Ticket # {helpData?.ticketno}</Text>,
                    },
                ]}
            />
            <Card className='shadow-d radius-12 border0 mb-3'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Space>
                            <Button className='border0 p-0 bg-transparent' onClick={()=>navigate('/helpdesk')}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Text className='fw-500'>Ticket #{helpData.ticketno}</Text>
                            <Text
                                className={
                                    helpData.status === "Resolved" ? "green fs-12 badge-cs" :
                                    helpData.status === "Pending" ? "blue fs-12 badge-cs" :
                                    helpData.status === "In Progress" ? "orange fs-12 badge-cs" : null
                                } 
                            >
                                {helpData?.status}
                            </Text>
                        </Space>
                    </Col>  
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='pagination'
                            showSorterTooltip={false}
                            rowHoverable={false}
                            showHeader={false}
                            pagination={false}
                        />
                    </Col>
                    <Col span={24}>
                        <HelpComponent clientname={helpData?.clientname} />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export { HelpSupportSingleView }
